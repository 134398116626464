export class Category {

  id: string;
  imageCategory?: boolean;
  name?: string;
  region?: number;
  color?: string;
  priority?: number;
  visible?: boolean;
  v2FilterSubscribed?: number;
  categoryImageCount?: number;

  constructor(category) {
    this.id = category.id;
    this.name = category.name || '';
    this.region = category.region || 1;
    this.color = category.color || '#2196F3';
    this.priority = category.priority;
    this.imageCategory = category.imageCategory;
    this.visible = category.visible;
    this.v2FilterSubscribed = category.v2FilterSubscribed;
    this.categoryImageCount = category.categoryImageCount;
  }
}
