import {NgModule} from '@angular/core';
import {SubscriptionStatusPipe} from './subscription-status.pipe';
import {NotificationsStatusPipe} from './notifications-status.pipe';
import {PlatformPipe} from './platform.pipe';

@NgModule({
  declarations: [
    PlatformPipe,
    SubscriptionStatusPipe,
    NotificationsStatusPipe
  ],
  exports: [
    PlatformPipe,
    SubscriptionStatusPipe,
    NotificationsStatusPipe
  ]
})

export class StatusFiltersModule{}
