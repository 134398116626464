import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, BehaviorSubject} from 'rxjs';

import {Category} from '../categories.model';
import {CategoryService} from './category.service';
import {ApiConfigurationService} from '../../../../shared/services/api-configuration.service';

@Injectable()
export class LibraryImageCategoriesService implements Resolve<Array<Category>> {

  onCategoriesChanged: BehaviorSubject<Array<Category>> = new BehaviorSubject([]);
  categories: Category[];
  loadingResults: BehaviorSubject<boolean>;
  loadingAction: Array<number> = [];
  onLoadingActionChanged: BehaviorSubject<Array<number>>;
  categoriesFilterChanged = new BehaviorSubject<string>('all');
  categoriesFilter: string;

  protected basePath = this.apiConfiguration.basePath();

  constructor(
    private categoryServiceAPI: CategoryService,
    private apiConfiguration: ApiConfigurationService) {
      this.loadingResults = new BehaviorSubject<boolean>(false);
      this.onLoadingActionChanged = new BehaviorSubject([]);
      this.categoriesFilter = 'all';

    this.categoriesFilterChanged.subscribe(
      res => {
        this.categoriesFilter = res;
        this.filterCategories();
      }
    );
  }

  /**
   * The Categories Main Resolver
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<Array<Category>> | Promise<Array<Category>> | Array<Category>}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<Category>> | Promise<Array<Category>> | Array<Category> {
    return new Promise((resolve, reject) => {

      Promise.all([
        this.getCategories()
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  getCategories(): Promise<Array<Category>> {
    return new Promise((resolve, reject) => {
      this.loadingResults.next(true);
      this.categoryServiceAPI.cgetCategory()
        .subscribe((response: Array<Category>) => {
            this.categories = response;
            if (this.categories !== null) {
              this.categories = this.categories.map(category => {
                return new Category(category);
              });
              this.filterCategories();
            }
            this.loadingResults.next(false);
            resolve(this.categories);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetching categories list');
            this.loadingResults.next(false);
            reject(error);
          });
    });
  }

  filterCategories() {
    let filteredCategories;
    if (this.categoriesFilter === 'all') {
      filteredCategories = this.categories;
    } else if (this.categoriesFilter === 'visible') {
      filteredCategories = this.categories.filter(cat => cat.visible);
    } else if (this.categoriesFilter === 'hidden') {
      filteredCategories = this.categories.filter(cat => cat.visible === false);
    }
    this.onCategoriesChanged.next(filteredCategories);
  }

  /* Save new category */
  saveCategory(category: Category) {
    return new Promise((resolve, reject) => {
      this.categoryServiceAPI.postCategory(category)
        .subscribe(
          (response) => {
            this.getCategories();
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            reject(error);
          });
    });
  }

  /* Save updated category */
  updateCategory(editedCategoryId, category) {
    return new Promise((resolve, reject) => {
      this.categoryServiceAPI.patchCategory(editedCategoryId, category)
        .subscribe(
          (response) => {
            this.getCategories();
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            reject(error);
          });
    });
  }

  /* Delete category */
  deleteCategory(category) {
    return new Promise((resolve, reject) => {
      this.categoryServiceAPI.deleteCategory(category.id)
        .subscribe(
          (response) => {
            this.getCategories();
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            reject(error);
          });
    });
  }


  /**
   * Toggle selected category by id
   * @param id
   */
  toggleActionLoader(id) {
    // First, check if we already have that image as selected...
    if (this.loadingAction.length > 0) {
      const index = this.loadingAction.indexOf(id);
      if (index !== -1) {
        this.loadingAction.splice(index, 1);

        // Trigger the next event
        this.onLoadingActionChanged.next(this.loadingAction);

        // Return
        return;
      }
    }
    // If we don't have it, push as selected
    this.loadingAction.push(id);

    // Trigger the next event
    this.onLoadingActionChanged.next(this.loadingAction);
  }


}
