import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subscriptionStatus'
})
export class SubscriptionStatusPipe implements PipeTransform {

  subscriptionStatus = [
          {value: 0, label: 'Any'},
          {value: 1, label: 'Subscribed'},
          {value: 2, label: 'Not subscribed'}
        ];

  transform(groupValue: number): string {
       const groupName = this.subscriptionStatus.find(({value}) => value === groupValue)['label'];
        return groupName;
      }

}
