import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'platform'
})
export class PlatformPipe implements PipeTransform {

  platforms = [
    {value: 0, label: 'All'},
    {value: 1, label: 'iOS'},
    {value: 2, label: 'Android'}
  ];

  transform(groupValue: number): string {
    const groupName = this.platforms.find(({value}) => value === groupValue)['label'];
    return groupName;
  }

}
