import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notificationsStatus'
})
export class NotificationsStatusPipe implements PipeTransform {

  notificationStatus = [
    {value: 0, label: 'Any settings'},
    {value: 1, label: 'Enabled'},
    {value: 2, label: 'Disabled'}
  ];

  transform(groupValue: number): string {
    const groupName = this.notificationStatus.find(({value}) => value === groupValue)['label'];
    return groupName;
  }

}
