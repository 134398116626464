import {AbstractControl, ValidatorFn} from '@angular/forms';

export function forbiddenValueValidator(forbiddenValues: Array<string>, caseSensitive?: boolean): ValidatorFn  {

  return (control: AbstractControl): { [key: string]: any } | null => {

    let forbidden;
    if (control.value !== null) {
      for (const forbiddenValue of forbiddenValues) {

        let forbiddenCheck;
        if (caseSensitive) {
          forbiddenCheck = forbiddenValue === control.value;
        } else {
          forbiddenCheck = forbiddenValue.toLowerCase() === control.value.toLowerCase();
        }

        if (forbiddenCheck) {
          forbidden = true;
          break;
        } else {
          forbidden = false;
        }
      }
      return forbidden ? {'forbiddenValue' : {value: control.value}} : null;
    }
  };
}
